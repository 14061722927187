import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { Subject, startWith, takeUntil } from 'rxjs';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { slideInAnimation } from 'src/app/utils/animations';
import { enUS, fr } from 'date-fns/locale';
import { LocalizationUtils } from 'src/app/utils/localization-utils';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [slideInAnimation]
})
export class DashboardComponent implements OnInit, OnDestroy {

  language : string = "en";

  // Mobile
  mobile: boolean = false;

  private ngUnsubscribe = new Subject<void>();
  
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public dateConfig: DateFnsConfigurationService,
    private readonly translateService: TranslateService,
    private readonly deviceStateService: DeviceStateService
  ) {
    this.mobile = deviceStateService.isMobileResolution();
  }

  ngOnInit(): void {

    this.language = LocalizationUtils.getLanguage();

    this.deviceStateService.screenResized
      .pipe(
        startWith(this.mobile),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(isMobile => this.mobile = isMobile);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  goHome () {
		this.router.navigate(['/'], { relativeTo: this.route });
  }

  prepareRoute(outlet: RouterOutlet) {
    return (!this.mobile && outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']);
  }

  setLanguage (lang: string) {

    localStorage.setItem('locale', lang);
    this.translateService.use(lang);

    this.language = LocalizationUtils.getLanguage();

    switch (this.language) {
        case 'en':
            this.dateConfig.setLocale(enUS);
        break;
        case 'fr':
            this.dateConfig.setLocale(fr);
        break;
    }
}
}