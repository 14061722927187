import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, startWith, takeUntil } from 'rxjs';
import { Order } from 'src/app/models/model';
import { BookingsService } from 'src/app/services/bookings.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import * as htmlToImage from 'html-to-image';
import jsPDF, { jsPDFOptions } from 'jspdf';
import { LoadingService } from 'src/app/services/loading.service';
import { atcb_action } from "add-to-calendar-button";
import { format, addDays } from 'date-fns';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, OnDestroy {

  @ViewChild('dataToExport') public dataToExport: ElementRef | undefined;
  @ViewChild('downloadButton') public downloadButton: ElementRef | undefined;
  @ViewChild('calendarButton') public calendarButton: ElementRef | undefined;
  
  order?: Order;
  total : number = 0;
  status : string = "error";

  mobile : boolean = false;
  private ngUnsubscribe = new Subject<void>();
  
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly deviceStateService: DeviceStateService,
    private readonly loadingService: LoadingService,
    private readonly bookingsService: BookingsService
  ) {
    this.mobile = this.deviceStateService.isMobileResolution();
  }

  ngOnInit(): void {
    this.loadOrder();
    this.deviceStateService.screenResized
      .pipe(
          startWith(this.mobile),
          takeUntil(this.ngUnsubscribe)
      )
      .subscribe(isMobile => this.mobile = isMobile);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private async loadOrder () {

    const orderId = this.route.snapshot.queryParamMap.get('orderId');
    
    if (!orderId) {
        this.router.navigate(['/']);
        return;
    }

    const order = await this.bookingsService.getClosedOrder(orderId);
    this.order = order;
    
    if (!this.order) {
        this.router.navigate(['/']);
        return;
    } else {

        this.total = (this.order?.table?.price || 0) + (this.order?.extras || []).map(e => e.price * e.quantity).reduce((a,b) => a + b, 0);

        if (this.order.status) {
        this.status = this.order.status;
        }
    }
  }

  goHome () {
		this.router.navigate(['/'], { relativeTo: this.route });
  }

  public downloadPDF(): void {
    if (this.dataToExport) {

      this.loadingService.show();

      if (this.downloadButton) {
        this.downloadButton.nativeElement.style.display = 'none';
      }
      if (this.calendarButton) {
        this.calendarButton.nativeElement.style.display = 'none';
      }
      
      const width = this.dataToExport.nativeElement.clientWidth;
      const height = this.dataToExport.nativeElement.clientHeight + 40;
      
      htmlToImage.toPng(this.dataToExport.nativeElement, {
        width: width,
        height: height
      }).then((result : any) => {

        let orientation: ("p" | "portrait" | "l" | "landscape") = 'p';
        let  unit: ("pt" | "px" | "in" | "mm" | "cm" | "ex" | "em" | "pc") = "pt";

        let widthRatio = height / 1.414;
        orientation = 'p'; // Force

        let offset = 0;
        if (widthRatio < width) {
            offset = -((width - widthRatio) / 2);
        } else {
            offset = ((widthRatio - width) / 2);
        }

        let jsPdfOptions : jsPDFOptions = {
          orientation: orientation,
          unit: unit,
          format: [widthRatio, height]
        };

        const pdf = new jsPDF(jsPdfOptions);
        pdf.addImage(result, 'PNG', 0 + offset, 0, width, height);
        const orderDate = new Date(this.order?.confirmationDate || 0);
        const yearStr = orderDate.getFullYear();
        let monthStr = ''+orderDate.getMonth();
        let dayStr = ''+orderDate.getDate();
        if (orderDate.getMonth() < 10) {
            monthStr = '0' + monthStr;
        }
        if (orderDate.getDate() < 10) {
            dayStr = '0' + dayStr;
        }
        pdf.save('Nightbite_order_' + yearStr + '' + monthStr + '' + dayStr + '_' + (this.order?.id || "") + '.pdf');

        if (this.downloadButton) {
          this.downloadButton.nativeElement.style.display = 'block';
        }
        if (this.calendarButton) {
          this.calendarButton.nativeElement.style.display = 'block';
        }
      })
      .catch((error : any) => {
        console.log(error);
      })
      .finally(() => {
        this.loadingService.hide();
      });
    }  
  }

  public addToCalendar () {

    // TODO: get the right CLOSING TIME of the Club for the event date to calculate the end of the event

    if (this.calendarButton) {

      let extrasString : string = "";
      if ((this.order?.extras || []).length > 0) {
        extrasString += ", " + this.order?.extras?.map(e => e.name).reduce((a, b) => a + ", " + b)
      }

      const startTimeDate = new Date(this.order?.info?.timeDate || 0);

      // End time: CLUB CLOSING TIME
      //const hours = Math.trunc((this.order?.club?.maxTime || 0) / 60);
      //const minutes = (this.order?.club?.maxTime || 0) - (hours * 60);
      const hours = 2; // TODO: temp
      const minutes = 10; // TODO: temp
      let hoursStr = ''+hours;
      if (hours < 10) {
        hoursStr = "0" + hoursStr;
      }
      let minutesStr = ''+minutes;
      if (minutes < 10) {
        minutesStr = "0" + minutesStr;
      }

      let endTimeDate = startTimeDate;

      // Check if it's a new day
      if (hours < startTimeDate.getHours()) {
        endTimeDate = addDays(endTimeDate, 1)
      }


      const config = {
        options: ["Google", "Apple"],
        timeZone: "Europe/Rome",
        name: this.order?.club?.name || "",
        description: this.order?.table?.name + extrasString,

        location: this.order?.club?.address,
        
        startDate: format(startTimeDate, "yyyy-MM-dd"),
        startTime: format(startTimeDate, "HH:mm"),
        endDate: format(endTimeDate, "yyyy-MM-dd"),
        endTime: hoursStr + ":" + minutesStr
        
      };
  
      atcb_action(config as any, this.calendarButton.nativeElement);
    }
  }
}
