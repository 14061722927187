<div *ngIf="order" #dataToExport style="width: 100%;">
    <div class="confirmation-header" [ngClass]="{'error': status !== 'succeeded' }">
        <div fxLayout="row" fxLayoutAlign="center center">
			<img
                id="img-logo"
				class="clickable"
				src="../../assets/img/logo.svg"
				(click)="goHome()"
			/>
		</div>

        <p *ngIf="status !== 'succeeded'" class="bold-30 color-white header-text">{{ 'confirmation_error_title' | translate }}</p>
        <p *ngIf="status === 'failed'" class="regular-20 color-white header-text" [innerHTML]="'confirmation_error_desc_1' | translate"></p>
        <p *ngIf="status === 'error'" class="regular-20 color-white header-text" [innerHTML]="'confirmation_error_desc_2' | translate"></p>

        <p *ngIf="status === 'succeeded'" class="bold-30 color-white header-text">{{ 'confirmation_ok_title' | translate }}</p>
        <p *ngIf="status === 'succeeded'" class="regular-20 color-white header-text" [innerHTML]="'confirmation_ok_desc_1' | translate"></p>
        <p class="regular-20 color-white header-text"><span class="bold-20 no-margin color-white">{{ 'confirmation_ok_desc_2' | translate }}</span> {{ order.id }}</p>
    </div>

    <div fxLayout="column" fxLayoutAlign="flex-start center" style="width: 100%;">
        <div id="confirmation-page" [ngClass]="{'mobile': mobile}" fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p *ngIf="status === 'succeeded'" class="regular-15 no-margin">
                <span [innerHTML]="'confirmation_content_ok' | translate"></span>
                <br /><br />
                {{ order.club?.address || "" }}
                <br />
                {{ order.club?.email || "" }}
                <br />
                {{ order.club?.phone || "" }}
            </p>
            
            <p *ngIf="status !== 'succeeded'" class="regular-15 no-margin" [innerHTML]="'confirmation_content_error' | translate"></p>

            <ng-container *ngIf="status === 'succeeded'">

                <div class="label-row" fxLayout="row" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
                    <div fxFlex="50%">
                        <p class="bold-15 no-margin">{{ 'order_information' | translate }}</p>
                    </div>
                    <div fxFlex="50%">
                        <p class="regular-15 no-margin text-right">{{ order.confirmationDate | dfnsFormat: 'dd/MM/yyyy HH:mm' }}</p>
                    </div>
                </div>

                <div class="separator-line"></div>

                <div *ngIf="order.club && order.info" fxLayout="row" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
                    <div fxFlex="50%">
                        <p class="bold-15">{{ order.club.name }}</p>
                        <div fxLayout="row" fxLayoutAlign="flex-start center" class="order-info-row">
                            <img class="img-info" src="../../assets/img/when_date.svg" />
                            <p class="regular-13 no-margin">{{ order.info.date | dfnsFormat: 'dd/MM/yyyy' }}</p>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="flex-start center" class="order-info-row">
                            <img class="img-info" src="../../assets/img/when_time.svg" />
                            <p class="regular-13 no-margin">{{ order.info.timeDate | dfnsFormat: 'HH:mm' }} <span *ngIf="order.info?.isDayAfter">&nbsp;&nbsp;(&nbsp;{{ order.info.timeDate | dfnsFormat: 'dd/MM/yyyy' }}&nbsp;)</span></p>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="flex-start center" class="order-info-row">
                            <img class="img-info" src="../../assets/img/people.svg" />
                            <p class="regular-13 no-margin">{{ order.info.numPeople }}&nbsp;{{ (order.info.numPeople > 1 ? 'people' : 'person') | translate }}</p>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="flex-start center" class="order-info-row">
                            <img class="img-info" src="../../assets/img/address.svg" />
                            <p class="regular-13 no-margin">{{ order.club.address }}</p>
                        </div>
                    </div>
                </div>

                <div *ngIf="order.table" fxLayout="row" fxLayoutAlign="flex-end flex-start" style="width: 100%;">
                    <div fxFlex="50%">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="summary-row">
                            <p class="bold-13 no-margin">{{ 'summary' | translate }}</p>
                        </div>
                
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="order-info-row">
                            <p class="regular-13 no-margin">{{ order.table.name || "" }}</p>
                            <p class="regular-13 no-margin">{{ (order.table.price || 0) / 100 | currency: 'EUR' : 'symbol' : '1.2-2' }}</p>
                        </div>
                
                        <div *ngFor="let extra of (order.extras || [])" fxLayout="row" fxLayoutAlign="space-between center" class="order-info-row">
                            <p class="regular-13 no-margin">
                                {{ extra.name }}
                                <span *ngIf="extra.quantity > 1">(x{{ extra.quantity }})</span>
                            </p>
                            <p class="regular-13 no-margin">{{ extra.price * extra.quantity / 100 | currency: 'EUR' : 'symbol' : '1.2-2' }}</p>
                        </div>
                
                        <div class="order-separator"></div>
                
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="order-info-row">
                            <p class="bold-13 no-margin">{{ 'total' | translate }}</p>
                            <p class="bold-13 no-margin color-pink">{{ total / 100 | currency: 'EUR' : 'symbol' : '1.2-2' }}</p>
                        </div>
                    </div>
                </div>

                <button #downloadButton (click)="downloadPDF()">{{ 'download_pdf' | translate }}</button>

                <button #calendarButton (click)="addToCalendar()" style="margin-top: 20px;">{{ 'add_to_calendar' | translate }}</button>

            </ng-container>
        </div>
    </div>
</div>