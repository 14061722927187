<div fxLayout="row" fxLayoutAlign="flex-start center" class="back-row">
    <div fxLayout="row" fxLayoutAlign="flex-start center" class="back-div" (click)="backClick()">
        <img class="img-back" src="../../assets/img/arrow_left.svg" />
        <p class="bold-15 color-pink no-margin" [innerHTML]="'booking_back' | translate"></p>
    </div>
</div>

<div class="timer" [ngClass]="{ 'mobile': mobile }">
    <p *ngIf="!mobile" class="regular-12 no-margin text-center timer-text" [innerHTML]="payment_timer"></p>
    <p *ngIf="mobile" class="regular-12 no-margin text-center" [innerHTML]="payment_timer_mobile"></p>
</div>

<div *ngIf="!mobile && order && order.club && order.info && order.table" fxLayout="row" fxLayoutAlign="flex-start flex-start" fxLayoutGap="20px" style="width: 100%;">
    
    <div  fxFlex="50%" fxLayout="column" fxLayoutAlign="flex-start flex-start">
        <ng-template [ngTemplateOutlet]="column1"></ng-template>
    </div>

    <div fxFlex="50%" fxLayout="column" fxLayoutAlign="flex-start flex-start">
        <ng-template [ngTemplateOutlet]="column2"></ng-template>
    </div>
</div>
<div *ngIf="mobile && order && order.club && order.info && order.table" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start" fxLayoutGap="0px" style="width: 100%;">
    <div fxFlex="100%" fxLayout="column" fxLayoutAlign="flex-start flex-start">
        <ng-template [ngTemplateOutlet]="column2"></ng-template>
    </div>

    <div fxFlex="100%" fxLayout="column" fxLayoutAlign="flex-start flex-start">
        <ng-template [ngTemplateOutlet]="column1"></ng-template>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center" class="summary-row">
    <p class="bold-13 no-margin">{{ 'terms_and_conditions_title' | translate }}</p>
</div>


<div fxLayout="row" fxLayoutAlign="flex-start flex-start">
    <mat-checkbox class="terms-checkbox" (change)="termsAndConditionsCheckedPlatform($event)">
    </mat-checkbox>
    <p class="regular-13 no-margin" [ngClass]="{'color-red': cbErrorPlatform }">
        {{ 'terms_and_conditions_checkbox_1' | translate | translateCut : 0 }}
        <span class="link-text" (click)="goToPlatformTerms()">{{ 'terms_and_conditions_checkbox_1' | translate | translateCut : 1 }}</span>
        {{ 'terms_and_conditions_checkbox_1' | translate | translateCut : 2 }}
    </p>
</div>

<div fxLayout="row" fxLayoutAlign="flex-start flex-start">
    <mat-checkbox class="terms-checkbox" (change)="termsAndConditionsCheckedClub($event)">
    </mat-checkbox>
    <p class="regular-13 no-margin" [ngClass]="{'color-red': cbErrorClub }">
        {{ 'terms_and_conditions_checkbox_2' | translate | translateCut : 0 }}
        <span class="link-text" (click)="goToClubTerms()">{{ 'terms_and_conditions_checkbox_2' | translate | translateCut : 1 }}</span>
        {{ 'terms_and_conditions_checkbox_2' | translate | translateCut : 2 }}
    </p>
</div>

<button *ngIf="!canProceed" mat-button class="button-neutral btn-pay" (click)="completeClickError()">{{ 'payment_cta' | translate }}</button>
<button *ngIf="canProceed" mat-button class="button-positive btn-pay" (click)="completeClick()">{{ 'payment_cta' | translate }}</button>


<ng-template #column1>
    <ng-container *ngIf="order && order.club && order.info && order.table">
        <mat-form-field appearance="outline" class="full-width" [ngClass]="{'top-margined': mobile}" hideRequiredMarker>
            <mat-label>{{ 'payment_name' | translate }}</mat-label>
            <input
                type="text"
                matInput
                [placeholder]="'payment_name' | translate"
                [formControl]="name"
                autocomplete="off"
            />
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="full-width" hideRequiredMarker>
            <mat-label>{{ 'payment_surname' | translate }}</mat-label>
            <input
                type="text"
                matInput
                [placeholder]="'payment_surname' | translate"
                [formControl]="surname"
                autocomplete="off"
            />
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="full-width" hideRequiredMarker>
            <mat-label>{{ 'payment_email' | translate }}</mat-label>
            <input
                type="text"
                matInput
                [placeholder]="'payment_email' | translate"
                [formControl]="email"
                autocomplete="off"
            />
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width" [floatLabel]="'always'" hideRequiredMarker>
            <mat-label>{{ 'phone' | translate }}</mat-label>
            <input
                maxlength="20"
                matInput
                [formControl]="phone"
                type="text"
                ng2TelInput
                [ng2TelInputOptions]="{ initialCountry: 'fr', separateDialCode: true, autoPlaceholder: 'off' }"
                (hasError)="(phone.invalid)"
                (intlTelInputObject)="telInputObject($event)"
                (countryChange)="onCountryChange($event)"
                (keypress)="numberOnly($event)"
                autocomplete="off"
            />
        </mat-form-field>

        <!--
        <mat-form-field appearance="outline" class="full-width" hideRequiredMarker>
            <mat-label>{{ 'select_optional_occasion' | translate }}</mat-label>
            <mat-select [(value)]="occasionChoice">
                <mat-option *ngFor="let op of occasionOptions" [value]="op">
                    {{ op }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        -->
    
        <div fxLayout="row" fxLayoutAlign="space-between center" class="summary-row">
            <p class="bold-13 no-margin">{{ 'card_info_title' | translate }}</p>
        </div>
        <p class="regular-13 no-margin">{{ 'card_info_desc' | translate }}</p>

        <div id="link-authentication-element" style="margin-bottom: 20px;">
            <!--Stripe.js injects the Link Authentication Element-->
        </div>
        <div id="payment-element">
            <!--Stripe.js injects the Payment Element-->
        </div>
    </ng-container>
</ng-template>

<ng-template #column2>
    <ng-container *ngIf="order && order.club && order.info && order.table">
        <p class="bold-15 club-name">{{ order.club.name }}</p>
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="order-info-row">
            <img class="img-info" src="../../assets/img/when_date.svg" />
            <p class="regular-13 no-margin">{{ order.info.date | dfnsFormat: 'dd/MM/yyyy' }}</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="order-info-row">
            <img class="img-info" src="../../assets/img/when_time.svg" />
            <p class="regular-13 no-margin">{{ order.info.timeDate | dfnsFormat: 'HH:mm' }} <span *ngIf="order.info.isDayAfter">&nbsp;&nbsp;(&nbsp;{{ order.info.timeDate | dfnsFormat: 'dd/MM/yyyy' }}&nbsp;)</span></p>
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="order-info-row">
            <img class="img-info" src="../../assets/img/people.svg" />
            <p class="regular-13 no-margin">{{ order.info.numPeople }}&nbsp;{{ (order.info.numPeople > 1 ? 'people' : 'person') | translate }}</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="order-info-row">
            <img class="img-info" src="../../assets/img/address.svg" />
            <p class="regular-13 no-margin">{{ order.club.address }}</p>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center" class="summary-row">
            <p class="bold-13 no-margin">{{ 'summary' | translate }}</p>
            <button mat-button class="button-primary btn-edit" (click)="backClick()">{{ 'edit' | translate }}</button>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center" class="order-info-row">
            <p class="regular-13 no-margin">{{ order.table.name }}</p>
            <p class="regular-13 no-margin">{{ order.table.price / 100 | currency: 'EUR' : 'symbol' : '1.2-2' }}</p>
        </div>

        <div *ngFor="let extra of order.extras" fxLayout="row" fxLayoutAlign="space-between center" class="order-info-row">
            <p class="regular-13 no-margin">
                {{ extra.name }}
                <span *ngIf="extra.quantity > 1">(x{{ extra.quantity }})</span>
            </p>
            <p class="regular-13 no-margin">{{ extra.price * extra.quantity / 100 | currency: 'EUR' : 'symbol' : '1.2-2' }}</p>
        </div>
        
        <div class="order-separator"></div>

        <div fxLayout="row" fxLayoutAlign="space-between center" class="order-info-row">
            <p class="bold-13 no-margin">{{ 'total' | translate }}</p>
            <p class="bold-13 no-margin color-pink">{{ total / 100 | currency: 'EUR' : 'symbol' : '1.2-2' }}</p>
        </div>

        <div class="order-separator"></div>

        <div fxLayout="row" fxLayoutAlign="space-between center" class="summary-row">
            <p class="bold-13 no-margin">{{ 'payment_disclaimer_title' | translate }}</p>
        </div>
        <p class="regular-13 no-margin" [innerHTML]="payment_disclaimer_content"></p>
    </ng-container>
</ng-template>