export class LocalizationUtils {

    public static getLanguage(): 'en' | 'fr' {
        const lang = localStorage.getItem('locale');
        
        if (!!lang && (lang === 'en' || lang === 'fr')) {
            return lang as 'en' | 'fr';
        }

        const userLang = navigator.language.substr(0, 2);
        if (userLang === 'fr') {
            return 'fr';
        }

        return 'en';
	}
}