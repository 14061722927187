<div id="dashboard-container">
	<div id="menu-top-bar" [ngClass]="{'menu-top-bar-desk': !mobile, 'menu-top-bar-mobile': mobile }">
		<div fxLayout="row" fxLayoutAlign="center center">
			<img
                id="img-logo"
				class="clickable"
				src="../../assets/img/logo.svg"
				(click)="goHome()"
			/>
		</div>

        <p class="bold-15 no-margin color-white floating-language" [ngClass]="{'mobile': mobile }" mat-button [matMenuTriggerFor]="languageMenu">
            <span *ngIf="language === 'en'">{{ 'english_short' | translate }}</span>
            <span *ngIf="language === 'fr'">{{ 'french_short' | translate }}</span>
        </p>
        <mat-menu #languageMenu="matMenu">
            <button mat-menu-item>
              <p class="regular-15 no-margin" (click)="setLanguage('en')">{{ 'english' | translate }}</p>
            </button>
            <button mat-menu-item>
                <p class="regular-15 no-margin" (click)="setLanguage('fr')">{{ 'french' | translate }}</p>
            </button>
        </mat-menu>
	</div>

	<div fxLayout="column" id="pages-container" [ngClass]="{'pages-container-desk': !mobile, 'pages-container-mobile': mobile }">
		<div fxFlex fxLayoutAlign="flex-start center" fxLayout="row" style="width: 100%">
			<div fxFlex="100%" [ngClass]="{'main-page-container-desk': !mobile, 'main-page-container-mobile': mobile }" [@routeAnimations]="prepareRoute(outlet)">
				<router-outlet #outlet="outlet"></router-outlet>
			</div>
		</div>
	</div>
</div>
