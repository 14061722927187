<div class="container">
    <div fxLayout="column" fxlayoutAlign="flex-start flex-start">
        <p class="regular-15 no-margin"><span class="bold-15">Table Booking</span> policy:<br /><br /></p>
        <p class="regular-15 no-margin" [innerHTML]="clubTerms?.reservationPolicy || ''"></p>
        <p class="regular-15 no-margin"><span class="bold-15"><br /><br />Payment Refund</span> policy:<br /><br /></p>
        <p class="regular-15 no-margin" [innerHTML]="clubTerms?.refundPolicy || ''"></p>
    </div>    


    <!-- TODO: TERMS&CONDITIONS Platform -->
    
</div>
