import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ClubTerms } from '../models/model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class TermsService {

  BACKEND_HOST = environment.backend;

  constructor(
    private readonly http: HttpClient
  ) { }

  public getClubTerms(clubId: string): Promise<ClubTerms> {
    return firstValueFrom(this.http.get<ClubTerms>(`${this.BACKEND_HOST}/clubs/${ clubId }/terms`));
  }
}
