import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, of, startWith, switchMap, take, takeUntil } from 'rxjs';
import { Order } from 'src/app/models/model';
import { BookingsService } from 'src/app/services/bookings.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { differenceInSeconds } from 'date-fns';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

import { Appearance, Stripe, StripeElement, StripeElements, loadStripe } from '@stripe/stripe-js';
import { SnotifyService } from 'ng-snotify';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-booking-payment',
  templateUrl: './booking-payment.component.html',
  styleUrls: ['./booking-payment.component.scss']
})
export class BookingPaymentComponent implements OnInit, OnDestroy {

  order?: Order;

  name = new FormControl('', [Validators.required]);
  surname = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);

  phone = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(/^\d{1,14}$/)
  ]);
  phoneCountry = 'lu';
  phonePrefix: any = '352';

  occasionChoice : string = "";
  occasionOptions: string[] = ["AAAAA", "BBBBB", "CCCCC", "DDDDD", "EEEEE", "FFFFF"];

  @ViewChild("platformTerms") platformTerms: ElementRef<HTMLElement> | undefined;
  @ViewChild("clubTerms") clubTerms: ElementRef<HTMLElement> | undefined;

  termsPlatform: boolean = false;
  termsClub: boolean = false;
  cbErrorPlatform: boolean = false;
  cbErrorClub: boolean = false;
  

  mobile: boolean = false;  
  private ngUnsubscribe = new Subject<void>();

  total : number = 0;

  payment_disclaimer_content: string = "";
  
  payment_timer_original: string = "";
  payment_timer_original_mobile: string = "";
  payment_timer: string = "";
  payment_timer_mobile: string = "";
  startingDateTime: Date = new Date();
  secondsAvailable: number = 299;
  stopTimer: boolean = false;

  canProceed : boolean = false;

  stripe?: Stripe | null;
  elements?: any;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly loadingService: LoadingService,
    private readonly toastService: SnotifyService,
    private readonly translateService: TranslateService,
    private readonly deviceStateService: DeviceStateService,
    private readonly bookingsService: BookingsService
  ) {
    this.mobile = this.deviceStateService.isMobileResolution();
  }

  ngOnInit()  : void {

    this.loadingService.show();

    this.bookingsService.order
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(async order => {
        
        this.order = order;
        if (this.order) {
            
            const checkoutObj = await this.bookingsService.askCheckout(this.order);
            this.order.id = checkoutObj.orderId;

            this.stripe = await loadStripe(environment.stripeKey, {
                stripeAccount: checkoutObj.stripeClientId
            });
            this.setupStripe(checkoutObj.clientSecret);
        }

        if (!order || !order.club || !order.info || !order.table) {
          this.backClick();
          return;
        }

        this.payment_disclaimer_content = this.translateService.instant('payment_disclaimer_content').replace('@@@', (this.order?.club?.name || ''));

        this.total = (this.order?.table?.price || 0) + (this.order?.extras || []).map(e => e.price * e.quantity).reduce((a,b) => a + b, 0);

        console.log(this.clubTerms)

        this.loadingService.hide();
      });
    
    this.payment_timer_original = this.translateService.instant('payment_timer');
    this.payment_timer = this.payment_timer_original.replace('###', "4:59");

    this.payment_timer_original_mobile = this.translateService.instant('payment_timer_mobile');
    this.payment_timer_mobile = this.payment_timer_original_mobile.replace('###', "4:59");

    this.deviceStateService.screenResized
      .pipe(
          startWith(this.mobile),
          takeUntil(this.ngUnsubscribe)
      )
      .subscribe(isMobile => this.mobile = isMobile);

    this.startingDateTime = new Date();
    this.setTimer();

    this.name.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.checkIfCanProceed());
    this.surname.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.checkIfCanProceed());
    this.email.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.checkIfCanProceed());
  }

  ngOnDestroy(): void {
    this.stopTimer = true;
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private async setupStripe (clientSecret: string) {

    if (this.stripe && clientSecret) {

      const appearance : Appearance = {
        theme: 'stripe',
        variables: {
            colorTextPlaceholder: "black"
        },
        rules: {
            '.Input': {
                border: '1px solid black'
            }
        }
      };
      this.elements = this.stripe?.elements({ appearance, clientSecret });
    
      const paymentElementOptions = {
        layout: "tabs",
      };
    
      const paymentElement = this.elements.create("payment", paymentElementOptions);
      paymentElement.mount("#payment-element");
    }
  }

  private setTimer () {
    setTimeout(() => {
      const secDiff = differenceInSeconds(new Date(), this.startingDateTime);
      let secondsLeft = this.secondsAvailable - secDiff;
      if (secondsLeft < 0) {
        secondsLeft = 0;
        this.backClick();
      }

      let minutesAvailable = Math.trunc(secondsLeft / 60);
      let secondsAvailable = secondsLeft - (60 * minutesAvailable);
      let minutesAvailableStr = "" + minutesAvailable;
      let secondsAvailableStr = "" + secondsAvailable;
      if (secondsAvailable < 10) {
        secondsAvailableStr = "0" + secondsAvailableStr;
      }

      this.payment_timer = this.payment_timer_original.replace("###", minutesAvailableStr + ":" + secondsAvailableStr);
      this.payment_timer_mobile = this.payment_timer_original_mobile.replace("###", minutesAvailableStr + ":" + secondsAvailableStr);

      if (!this.stopTimer) {
        this.setTimer();
      }
    }, 100);
  }

  private checkIfCanProceed () {
    this.canProceed = this.name.valid && this.surname.valid && this.email.valid && this.phone.valid && this.termsPlatform && this.termsClub;
  }

  backClick () {

    if (this.order && this.order.id) {
        this.bookingsService.deletePendingOrder(this.order?.id);
    }

    this.location.back();
  }

  telInputObject(obj : any) {
    obj.setCountry(this.phoneCountry);
  }

  onCountryChange(ev : any) {
		this.phonePrefix = ev.dialCode;
		this.phoneCountry = ev.iso2;
	}

  completeClickError () : boolean {

    let canProceed : boolean = false;
    this.name.markAsTouched();
    this.surname.markAsTouched();
    this.email.markAsTouched();
    this.phone.markAsTouched();

    this.cbErrorPlatform = !this.termsPlatform;
    this.cbErrorClub = !this.termsClub;

    /*

    "error_checkout_name": "",
    "error_checkout_surname": "",
    "error_checkout_email": "",
    "error_checkout_phone": "",
    "error_checkout_termsPlatform": "",
    "error_checkout_termsClub": "",
    "error_checkout_invalid_request": ""
    
    */

    if (!this.name.valid) {
      this.toastService.error(this.translateService.instant('error_checkout_name'), this.translateService.instant('error_checkout_title_1'));
    } else if (!this.surname.valid) {
      this.toastService.error(this.translateService.instant('error_checkout_surname'), this.translateService.instant('error_checkout_title_1'));
    } else  if (!this.email.valid) {
      this.toastService.error(this.translateService.instant('error_checkout_email'), this.translateService.instant('error_checkout_title_1'));
    } else  if (!this.phone.valid) {
      this.toastService.error(this.translateService.instant('error_checkout_phone'), this.translateService.instant('error_checkout_title_1'));
    } else  if (!this.termsPlatform) {
      this.toastService.error(this.translateService.instant('error_checkout_termsPlatform'), this.translateService.instant('error_checkout_title_1'));
    } else  if (!this.termsClub) {
      this.toastService.error(this.translateService.instant('error_checkout_termsPlatform'), this.translateService.instant('error_checkout_title_1'));
    } else {
      canProceed = true;
    }
    
    return canProceed;
  }

  async completeClick () {

    const canProceed = this.completeClickError();

    if (canProceed) {

      if (this.order && this.order.id && this.stripe && this.elements) {

        this.loadingService.show();

        const phone = '+' + this.phonePrefix + this.phone.value;
        
        await this.bookingsService.editBilling(this.order.id, ''+this.name.value, ''+this.surname.value, ''+this.email.value, phone);

        const error = await this.bookingsService.payOrder(this.stripe, this.elements, this.order.id, this.name.value + " " + this.surname.value, ''+this.email.value, phone);
        
        this.loadingService.hide();

        this.toastService.error(error, "Error");
        
      } else {
        
        this.toastService.error(this.translateService.instant('error_checkout_invalid_request'), this.translateService.instant('error_checkout_title_2'));
      }
    }
  }

  termsAndConditionsCheckedPlatform (ev: any) {
    this.termsPlatform = ev.checked;
    this.cbErrorPlatform = !ev.checked;
    this.checkIfCanProceed();
  }

  termsAndConditionsCheckedClub (ev: any) {
    this.termsClub = ev.checked;
    this.cbErrorClub = !ev.checked;
    this.checkIfCanProceed();
  }

  numberOnly(event : any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  goToPlatformTerms () {
    window.open('/terms', '_blank');
  }

  goToClubTerms () {
    if (this.order?.club) {
        window.open('/terms?cid=' + this.order.club.id, '_blank');
    }
  }
}
