<div fxLayout="column" fxLayoutAlign="flex-start center" style="min-height: 100%; padding-bottom: 40px;">
    <div fxLayout="row" fxLayoutAlign="center flex-start" style="width: 100%;" class="desc-section">
        <img
            class="img-bracket left"
            src="../../assets/img/bracket_open.png"
        />
        <p class="regular-15 desc-text" [innerHTML]="'home_desc' | translate"></p>

        <img
            class="img-bracket right"
            src="../../assets/img/bracket_close.png"
        />
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="center flex-start" [ngClass]="{'home-tiles-desk': !mobile, 'home-tiles-mobile': mobile }">
            
        <div *ngFor="let club of clubs" [ngClass]="{ 'tile-container-desk': !mobile, 'tile-container-mobile': mobile }" fxLayout="column" fxLayoutAlign="flex-start center" (click)="tileClick(club.id)">
            <div [ngClass]="{'home-tile-desk': !mobile, 'home-tile-mobile': mobile }">
                <img [ngClass]="{'home-tile-img-desk': !mobile, 'home-tile-img-mobile': mobile }" [src]="club.logo" />
            </div>
            <p [ngClass]="{'regular-15': !mobile, 'regular-13': mobile, 'home-tile-desc-desk': !mobile, 'home-tile-desc-mobile': mobile }">{{ club.name }}</p>
        </div>
    </div>
</div>