<div fxLayout="row" fxLayoutAlign="space-between flex-start" style="width: 100%;">
    
    <div fxFlex fxLayout="column" fxLayoutAlign="flex-start flex-start">
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="back-row">
            <div fxLayout="row" fxLayoutAlign="flex-start center" class="back-div" (click)="backClick()">
                <img class="img-back" src="../../assets/img/arrow_left.svg" />
                <p class="bold-15 color-pink no-margin" [innerHTML]="'booking_back' | translate"></p>
            </div>
        </div>
        
        <div class="extra-desc" fxLayout="row" fxLayoutAlign="flex-start flex-start">
            <img class="img-wallet" src="../../assets/img/wallet.svg" />
            <p class="regular-13 no-margin">{{ 'extra_desc' | translate }}</p>
        </div>
        
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="extra-item" [ngClass]="{'mobile': mobile}" *ngFor="let extra of extras; let index = index">
        
            <div class="extra-selector" [ngClass]="{'active': isExtraSelected(extra)}" (click)="toggleExtra(extra)">
                <p *ngIf="!isExtraSelected(extra)" class="bold-15 no-margin">+</p>
                <p *ngIf="isExtraSelected(extra)" class="bold-15 no-margin color-white">✓</p>
            </div>

            <div fxFlex fxLayout="row">
                <div fxFlex fxLayout="column" fxLayoutAlign="center flex-start" class="extra-info">
                    <p class="regular-13 no-margin">{{ extra.price / 100 | currency: 'EUR' : 'symbol' : '1.2-2' }} - {{ extra.name }}</p>
                    <p class="regular-13 no-margin" [innerHTML]="extra_buy_now"></p>
                </div>

                <div *ngIf="isExtraSelected(extra)" fxLayout="row" style="margin-top: 10px;">
                    <img class="cart-item-btn" src="/assets/img/item_remove.svg" (click)="decreaseExtra(extra)">
                    <p class="regular-13 no-margin">{{ extra.quantity }}</p>
                    <img class="cart-item-btn" src="/assets/img/item_add.svg" (click)="increaseExtra(extra)">
                </div>
            </div>
        
            <div class="extra-img">
                <img class="extra-pic" [src]="extra.pic" />
            </div>
        </div>
    </div>
    
    <div *ngIf="!mobile" class="checkout" fxLayout="column" fxLayoutAlign="flex-start flex-start">
        <app-checkout [club]="club" [info]="info" [table]="table" [extras]="selectedExtras" (extraRemoved)="extraRemoved($event)" (quantityChanged)="quantityChanged($event)" (orderCompleted)="orderCompleted($event)"></app-checkout>
    </div>
</div>

<div *ngIf="mobile">
    <div class="mobile-footer">
        <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
            <button fxFlex="70%" mat-button class="button-primary btn-mobile-bar" (click)="confirmOrderFromOutside.emit()">{{ 'complete_order' | translate }}</button>
            <button fxFlex="30%" mat-button class="button-primary btn-mobile-bar" (click)="viewOrder()">{{ 'view_order' | translate }}</button>
        </div>
    </div>
</div>
<div #checkoutmobile *ngIf="mobile" class="checkout-mobile" fxLayout="column" fxLayoutAlign="flex-start flex-start">
    <app-checkout [club]="club" [info]="info" [table]="table" [extras]="selectedExtras"  [confirmOrderFromOutside]="confirmOrderFromOutside" (extraRemoved)="extraRemoved($event)" (quantityChanged)="quantityChanged($event)" (orderCompleted)="orderCompleted($event)"></app-checkout>
</div>