<div fxLayout="row" fxLayoutAlign="flex-start center" class="back-row">
    <div fxLayout="row" fxLayoutAlign="flex-start center" class="back-div" (click)="backClick()">
        <img class="img-back" src="../../assets/img/arrow_left.svg" />
        <p class="bold-15 color-pink no-margin" [innerHTML]="'booking_back' | translate"></p>
    </div>
</div>

<div *ngIf="!searchDone" class="selection-bar" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start" style="width: 100%;">

    <mat-form-field [fxFlex]="mobile ? '33%' : '130px'" appearance="outline" class="first-field" [ngClass]="{'first-field-mobile': mobile }" hideRequiredMarker>
        <mat-label>{{ 'people_up' | translate }}</mat-label>
        <mat-select [(value)]="peopleChoice">
            <mat-option *ngFor="let op of peopleOptions" [value]="op.num">
                {{ op.text }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field [fxFlex]="mobile ? '33%' : '130px'" appearance="outline" class="nb-date-picker clickable" (click)="dateDatePicker.open()" hideRequiredMarker>
        <mat-label>{{ 'field_date' | translate }}</mat-label>
        <input
            matInput
            [matDatepicker]="dateDatePicker"
            [formControl]="dateControl"
            autocomplete="off"
            (dateChange)="dateChange($event)"
            readonly
            [min]="today"
            [matDatepickerFilter]="clubDateFilter"
            class="clickable"
        />
        <div class="mat-select-arrow-wrapper clickable"><div class="mat-select-arrow"></div></div>
        <mat-datepicker #dateDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field [fxFlex]="mobile ? '33%' : '130px'" appearance="outline" [ngClass]="{'last-field-mobile': mobile }" hideRequiredMarker>
        <mat-label>{{ 'field_time' | translate }}</mat-label>
        <mat-select [(value)]="timeChoice" (selectionChange)="timeChange($event)" [disabled]="timeOptions.length === 0">
            <mat-option *ngFor="let op of timeOptions" [value]="op.num">
                {{ op.text }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <button mat-button class="button-primary btn-find" [ngClass]="{'mobile': mobile }" (click)="findTables()">{{ 'table_select' | translate }}</button>
</div>

<div *ngIf="searchDone && tables.length === 0" fxLayout="column" fxLayoutAlign="flex-start center" style="width: 100%;">
    <p class="regular-15 no-margin text-center" [innerHTML]="'tables_empty' | translate"></p>
</div>

<div *ngIf="searchDone && tables.length > 0" fxLayout="column" fxLayoutAlign="flex-start flex-start">
    <div *ngFor="let table of tables; let last = last">

        <div fxLayout="row" fxLayoutAlign="space-between flex-start">
            <div class="table-item" fxLayout="column" fxLayoutAlign="flex-start flex-start">
                <p class="regular-15 no-margin">{{ table.name }}</p>
                <div class="table-info" fxLayout="row" fxLayoutAlign="space-between flex-start">
                    <img class="img-wallet" src="../../assets/img/wallet.svg" />
                    <p class="regular-15 no-margin">{{ table.price / 100 | currency: 'EUR' : 'symbol' : '1.2-2' }}</p>
                </div>
            </div>

            <button mat-button [ngClass]="{'button-primary': table.status === 'free', 'button-neutral': table.status === 'pending'}" (click)="selectTable(table)">{{ 'table_select' | translate }}</button>
        </div>

        <div *ngIf="table.status === 'pending'" class="pending-table-info">
            <p class="regular-15 no-margin">{{ 'tables_pending' | translate }}</p>
        </div>

        <p class="table-desc regular-15 no-margin" [innerHTML]="table.desc | translate"></p>

        <div class="table-pics" fxLayout="row" fxLayoutAlign="flex-start flex-start">
            <ng-container *ngFor="let pic of table.pics; let picIndex = index">
                <div class="table-pic-container">
                    <img class="table-pic" [src]="pic" (click)="showPicsClick(table, picIndex)" />
                </div>
            </ng-container>
        </div>
        
        <div *ngIf="!last" class="table-separator"></div>
    </div>
</div>

<ng-image-fullscreen-view
    [images]="imagesObject"
    [imageIndex]="selectedImageIndex"
    [show]="showFlag"
    [animationSpeed]="0.175"
    [paginationShow]="true"
    (close)="closeEventHandler()"
></ng-image-fullscreen-view>