import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { Club, Extra, Table, Order, ExtraOrder, OrderInfo } from 'src/app/models/model';
import { BookingsService } from 'src/app/services/bookings.service';



@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy, OnChanges {

  @Input() club? : Club;
  @Input() info? : OrderInfo;
  @Input() table? : Table;
  @Input() extras : ExtraOrder[] = [];
  @Input() confirmOrderFromOutside? : EventEmitter<void>;
  

  @Output() extraRemoved : EventEmitter<ExtraOrder> = new EventEmitter<ExtraOrder>();
  @Output() quantityChanged : EventEmitter<ExtraOrder> = new EventEmitter<ExtraOrder>();
  @Output() orderCompleted : EventEmitter<Order> = new EventEmitter<Order>();

  order : Order = {id: ""};

  total : number = 0;

  constructor(
  ) { }

  ngOnInit(): void {
    if (this.confirmOrderFromOutside) {
        this.confirmOrderFromOutside.subscribe(() => {
            this.completeOrder();
        })
    }
  }

  ngOnDestroy(): void {

  }
  
  ngOnChanges(changes: SimpleChanges): void {

    this.order.club = this.club;
    this.order.info = this.info;
    this.order.table = this.table;

    let newExtras : ExtraOrder[] = [];
    this.extras.forEach(extra => {
        console.log(extra)
        newExtras.push({...extra, quantity: extra.quantity})
    });
    this.order.extras = newExtras;

    this.calculateTotal();
  }

  decreaseExtra (extra: ExtraOrder) {
    const orderExtra = (this.order.extras || []).find(e => e.id === extra.id);
    if (orderExtra) {
        if (orderExtra.quantity > 1) {
            orderExtra.quantity -= 1;
            this.quantityChanged.emit(orderExtra);
        } else {
            this.removeExtra(extra);
        }
    }
    this.calculateTotal();
  }

  increaseExtra (extra: Extra) {
    const orderExtra = (this.order.extras || []).find(e => e.id === extra.id);
    if (orderExtra) {
        if (orderExtra.quantity < 10) { // TODO: MAX SUPPLY ? => AVAILABILITY MANAGEMENT
            orderExtra.quantity += 1;
            this.quantityChanged.emit(orderExtra);
        }
    }
    this.calculateTotal();
  }

  removeExtra (extra: ExtraOrder) {
    this.order.extras = (this.order.extras || []).filter(e => e.id !== extra.id);
    this.extraRemoved.emit(extra);
    this.calculateTotal();
  }

  completeOrder () {
    this.orderCompleted.emit(this.order);
  }

  private calculateTotal () {
    this.total = (this.order.table?.price || 0) + (this.order.extras || []).map(e => e.price * e.quantity).reduce((a,b) => a + b, 0);
  }
}
