<ng-container *ngIf="club && table">
    <div class="top-row" fxLayout="row" fxLayoutAlign="flex-start flex-start">
        <img class="img-cart" src="../../assets/img/cart.svg" />
        <p class="regular-15 no-margin" [innerHTML]="'extra_cart' | translate"></p>
    </div>

    <p class="bold-12 no-margin text-center">{{ club.name }}</p>
    <p class="regular-12 no-margin text-center">{{ 'table_reservetion' | translate }}</p>

    <div fxLayout="row" fxLayoutAlign="flex-start flex-start" class="cart-item first">
        <div fxFlex fxLayout="row">
            <div class="cart-item-btn"></div>
            <p class="regular-12 no-margin">&nbsp;</p>
            <div class="cart-item-btn"></div>
            <p class="regular-13 no-margin">{{ table.name }}</p>
        </div>
        <div fxLayout="row">
            <p class="regular-13 no-margin">{{ table.price / 100 | currency: 'EUR' : 'symbol' : '1.2-2' }}</p>
            <div class="cart-item-btn"></div>
        </div>
    </div>
    <div *ngFor="let extra of order.extras" fxLayout="row" fxLayoutAlign="flex-start flex-start" class="cart-item">
        <div fxFlex fxLayout="row">
            <img class="cart-item-btn" src="/assets/img/item_remove.svg" (click)="decreaseExtra(extra)">
            <p class="regular-13 no-margin">{{ extra.quantity }}</p>
            <img class="cart-item-btn" src="/assets/img/item_add.svg" (click)="increaseExtra(extra)">
            <p class="regular-13 no-margin">{{ extra.name }}</p>
        </div>
        <div fxLayout="row">
            <p class="regular-13 no-margin">{{ extra.price / 100 | currency: 'EUR' : 'symbol' : '1.2-2' }}</p>
            <img class="cart-item-btn" src="/assets/img/item_delete.svg" (click)="removeExtra(extra)">
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="flex-end flex-start">

        <p fxFlex class="regular-13 no-margin text-right total-text">{{ 'sub_total' | translate }}</p>
        <div fxLayout="row">
            <p class="regular-13 no-margin">{{ total / 100 | currency: 'EUR' : 'symbol' : '1.2-2' }}</p>
            <div class="cart-item-btn"></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="flex-end flex-start">

        <p fxFlex class="regular-13 no-margin text-right total-text">{{ 'total' | translate }}</p>
        <div fxLayout="row">
            <p class="regular-13 no-margin">{{ total / 100 | currency: 'EUR' : 'symbol' : '1.2-2' }}</p>
            <div class="cart-item-btn"></div>
        </div>
    </div>

    <div class="checkout-separator">
    </div>

    <div fxLayout="row" fxLayoutAlign="center flex-start">
        <button mat-button class="button-primary" (click)="completeOrder()">{{ 'complete_order' | translate }}</button>
    </div>
</ng-container>