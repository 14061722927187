import { EventEmitter, Injectable } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DeviceStateService {

    public screenName : string = "";
    public backClickDisabled: boolean = false;
	public iOS: boolean = false;

    fakeBackClick$: Subject<void> = new Subject<void>();
    
    private mobileResolution: boolean;

	public screenResized: EventEmitter<boolean>;

	constructor(
    ) {
		this.mobileResolution = window.innerWidth < 768;
		this.screenResized = new EventEmitter<boolean>();

		fromEvent(window, 'resize').subscribe(() => {
			const newVal = window.innerWidth < 768;
			if (newVal != this.mobileResolution) {
				this.screenResized.emit(newVal);
			}
			this.mobileResolution = newVal;
		});
	}

	public isMobileResolution(): boolean {
		return this.mobileResolution;
	}
}
