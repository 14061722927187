import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { BookingComponent } from './components/booking/booking.component';
import { BookingTableComponent } from './components/booking/booking-table/booking-table.component';
import { BookingExtraComponent } from './components/booking/booking-extra/booking-extra.component';
import { BookingPaymentComponent } from './components/booking/booking-payment/booking-payment.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { TermsComponent } from './components/terms/terms.component';

const routes: Routes = [
	{
		path: '',
        component: DashboardComponent,
        children: [
			{
				path: '',
				component: HomepageComponent,
                data: { animation: 'HomePage' }
			},
            {
				path: 'booking/:cid',
				component: BookingComponent,
                data: { animation: 'SlidePage' },
                children: [
                    {
                        path: '',
                        redirectTo: 'table',
                        pathMatch: 'full'
                    },
                    {
                        path: 'table',
                        component: BookingTableComponent,
                        data: { animation: 'SlidePage' }
                    },
                    {
                        path: 'extra',
                        component: BookingExtraComponent,
                        data: { animation: 'SlidePage' }
                    },
                    {
                        path: 'payment',
                        component: BookingPaymentComponent,
                        data: { animation: 'SlidePage' }
                    }
                ]
			}
        ]
	},
    {
        path: 'terms',
        component: TermsComponent
    },
    {
        path: 'confirmation',
        component: ConfirmationComponent
    },

    { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
