import { EventEmitter, Injectable } from '@angular/core';
import { firstValueFrom, fromEvent, Subject, map, catchError, tap, switchMap, of } from 'rxjs';
import { Club, OrderInfo, Table } from '../models/model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class TablesService {

  BACKEND_HOST = environment.backend;

  constructor(
    private readonly http: HttpClient
  ) { }

  public getTables(clubId: string, orderInfo: OrderInfo): Promise<Table[]> {
    
    return firstValueFrom(this.http.get<Table[]>(`${this.BACKEND_HOST}/clubs/${ clubId }/tables?pax=${ orderInfo.numPeople }&when=${ orderInfo.date }&whenTime=${ orderInfo.timeDate }`));
  }
}
