<div #mainContent *ngIf="club" fxLayout="column" fxLayoutAlign="flex-start center" style="min-height: 100%; padding-bottom: 40px;">
    <div fxLayout="row" fxLayoutAlign="center flex-start" style="width: 100%;" class="desc-section">
        <img
            class="img-bracket left"
            src="../../assets/img/bracket_open.png"
        />
        <p *ngIf="step === 1" class="regular-15 desc-text" [innerHTML]="'booking_desc_1' | translate"></p>
        <!--<p *ngIf="step === 2" class="regular-15 desc-text" [innerHTML]="'booking_desc_2' | translate"></p>-->
        <p *ngIf="step === 3" class="regular-15 desc-text" [innerHTML]="'booking_desc_3' | translate"></p>

        <img
            class="img-bracket right"
            src="../../assets/img/bracket_close.png"
        />
    </div>

    <p class="bold-30 club-name">{{ club.name }}</p>

    <div fxLayout="column" fxLayoutAlign="flex-start flex-start" [ngClass]="{'booking-container-desk': !mobile && step !== 2, 'booking-container-mobile': mobile && step !== 2, 'booking-container-large-desk': !mobile && step === 2, 'booking-container-large-mobile': mobile && step === 2 }">

        <div fxLayout="row" fxLayoutAlign="flex-start flex-start" class="booking-menu">
            <div class="booking-menu-item" [ngClass]="{'active': step === 1}">
                <p class="regular-15 booking-menu-item-desc">{{ 'booking_menu_1' | translate }}</p>
            </div>
            <!--
            <div class="booking-menu-item" [ngClass]="{'active': step === 2}">
                <p class="regular-15  booking-menu-item-desc">{{ 'booking_menu_2' | translate }}</p>
            </div>
            -->
            <div class="booking-menu-item" [ngClass]="{'active': step === 3}">
                <p class="regular-15  booking-menu-item-desc">{{ 'booking_menu_3' | translate }}</p>
            </div>
        </div>
        
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
</div>