import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, startWith, takeUntil } from 'rxjs';
import { Club } from 'src/app/models/model';
import { BookingsService } from 'src/app/services/bookings.service';
import { ClubsService } from 'src/app/services/clubs.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit, OnDestroy {

  @ViewChild("mainContent") mainContentDiv: ElementRef<HTMLElement> | undefined;

  club? : Club;

  step : number = 1;

  mobile : boolean = false;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly loadingService: LoadingService,
    private readonly clubsService: ClubsService,
    private readonly bookingsService: BookingsService,
    private readonly deviceStateService: DeviceStateService
  ) {
    this.mobile = this.deviceStateService.isMobileResolution();
  }

  ngOnInit(): void {

    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        if (this.mainContentDiv) {
          (this.mainContentDiv.nativeElement as HTMLElement).scrollIntoView();
        }
    });

    const clubId = this.route.snapshot.params['cid'];
    if (clubId) {

      this.loadingService.show();
      
      this.clubsService.getClub(clubId)
        .then(club => {
          this.club = club;
          this.bookingsService.setClub(club);
          this.loadingService.hide();
        })
        .catch(err => {
          console.log(err);
          this.loadingService.hide();
          this.router.navigate(['/']);
        });
      
    } else {
        this.router.navigate(['/']);
        return;
    }

    this.router.events
        .pipe(
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(ev => {
            if (ev instanceof NavigationEnd) {
                if (ev.url.includes('table')) {
                    this.step = 1;
                } else if (ev.url.includes('extra')) {
                    this.step = 2;
                } else if (ev.url.includes('payment')) {
                    this.step = 3;
                }
            }
        });

    this.deviceStateService.screenResized
      .pipe(
          startWith(this.mobile),
          takeUntil(this.ngUnsubscribe)
      )
      .subscribe(isMobile => this.mobile = isMobile);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  backClick () {
    this.location.back();
  }
}
