import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { ClubTerms } from 'src/app/models/model';
import { LoadingService } from 'src/app/services/loading.service';
import { TermsService } from 'src/app/services/terms.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  clubId: string = "";
  clubTerms? : ClubTerms;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly termsService: TermsService,
    private readonly loadingService: LoadingService
  ) { }

  ngOnInit(): void {

    this.route.queryParams
      .pipe(take(1))
      .subscribe(params => {

        if (!!params['cid']) {
            this.clubId = params['cid'];

            this.loadingService.show();
      
            this.termsService.getClubTerms(this.clubId)
              .then(terms => {
                this.clubTerms = terms;
                this.loadingService.hide();
              })
              .catch(err => {
                this.loadingService.hide();
                this.router.navigate(['/']);
              });

        } else {
            
          // TODO: platform terms and policy
        }
      });
  }
}
