import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest, of, startWith, switchMap, take, takeUntil } from 'rxjs';
import { Club, Order, OrderInfo, Table } from 'src/app/models/model';
import { BookingsService } from 'src/app/services/bookings.service';
import { ClubsService } from 'src/app/services/clubs.service';
import { TablesService } from 'src/app/services/tables.service';
import { format } from 'date-fns';
import { LoadingService } from 'src/app/services/loading.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { SnotifyService } from 'ng-snotify';

interface SelectOption {
  num: number;
  text: string;
}
@Component({
  selector: 'app-booking-table',
  templateUrl: './booking-table.component.html',
  styleUrls: ['./booking-table.component.scss']
})
export class BookingTableComponent implements OnInit, OnDestroy {

  imagesObject: Array<object> = [];
  showFlag: boolean = false;
  selectedImageIndex: number = -1;

  club?: Club;
  tables: Table[] = [];

  // FORM-1
  person_str: string = "";
  people_str: string = "";
  peopleOptions: SelectOption[] = [];
  peopleChoice: number = 4;
  // FORM-2
  dateControl = new UntypedFormControl(new Date(), []);
  today = new  Date();
  // FORM-3
  timeOptions: SelectOption[] = [];
  timeChoice: number = -1;

  searchDone : boolean = false;

  mobile : boolean = false;
  private ngUnsubscribe = new Subject<void>();

  // TODO: temp
  info?: OrderInfo;

  constructor(
    private readonly location: Location,
    private readonly router: Router,
    private readonly deviceStateService: DeviceStateService,
    private readonly loadingService: LoadingService,
    private readonly translateService: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly bookingsService: BookingsService,
    private readonly tablesService: TablesService,
    private readonly toastService: SnotifyService
  ) {
    this.mobile = this.deviceStateService.isMobileResolution();
  }

  ngOnInit(): void {

    this.bookingsService.resetInfo();
    //this.bookingsService.resetTable(); // Done already

    this.dateControl.setValue(this.today);

    this.bookingsService.club
        .pipe(
            takeUntil(this.ngUnsubscribe),
        )
        .subscribe(club => {
            if (club) {
                this.club = club;

                // Select the first day available in the Datepicker and the corresponding times
                if (!this.clubDateFilter(this.today)) {
                    let date = this.today;
                    for (let i = 0; i < 365; i++) {
                        date.setDate(date.getDate() + 1);
                        
                        if (this.clubDateFilter(this.today)) {
                            
                            this.dateControl.setValue(date);
                            break;
                        }
                    }
                }
                this.timeOptions = this.getTimeOptions();
            }
      });

    this.person_str = this.translateService.instant('person');
    this.people_str = this.translateService.instant('people');

    for (let i = 1; i < 11; i++) {

      let text = i + " " + this.people_str;
      if (i === 1) {
          text = 1 + " " + this.person_str;
      }
      this.peopleOptions.push({
          num: i,
          text: text
      })
    }

      this.deviceStateService.screenResized
      .pipe(
          startWith(this.mobile),
          takeUntil(this.ngUnsubscribe)
      )
      .subscribe(isMobile => this.mobile = isMobile);
  }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    clubDateFilter: ((date: Date | null) => boolean) = (date: Date | null) => {
        
        if (!date) {
            return false;
        }

        if (this.club) {
            
            return !!(this.club.timetable.base.find(tt => tt.day === date.getDay()));
        }
    
        return false;
    };

  private getTimeOptions () : SelectOption[] {

    let options : SelectOption[] = [];

    if (this.club) {

        const timetableItem = this.club.timetable.base.find(tt => tt.day === (this.dateControl.value as Date).getDay());
        if (timetableItem) {

            const minTime = timetableItem.open;
            const maxTime = timetableItem.close;

            let helpMax = maxTime;
            if (maxTime < minTime) {
                helpMax += 1440; // One day, in minutes
            }

            for (let i = minTime; i < helpMax; i += 15) {
                let hours = Math.trunc(i / 60);
                const minutes = i - (hours * 60);

                let extraDay = "";
                if (hours >= 24) {
                    hours -= 24;
                    extraDay = " (+1)"
                }
                let hoursStr = ''+hours;
                if (hours < 10) {
                    hoursStr = "0" + hoursStr;
                }
                let minutesStr = ''+minutes;
                if (minutes < 10) {
                    minutesStr = "0" + minutesStr;
                }
                options.push({ num: i, text: hoursStr + ":" + minutesStr + extraDay })
            }
        }
    }

    return options;
}

  backClick () {

    if (this.searchDone) {
        this.searchDone = false;
        this.tables = [];
    } else {
        this.location.back();
    }
  }

  dateChange (ev: any) {
    if (this.club) {
        
        this.timeOptions = this.getTimeOptions();
    }
    this.tables = [];
  }

  timeChange (ev: any) {
    this.tables = [];
  }
  
    findTables () {

        if (this.dateControl.valid && this.timeChoice >= 0) {

            // TODO: timezone support
            const clubTimezone = "Europe/Amsterdam";

            let date : Date = this.dateControl.value;
            date.setHours(0, 0, 0, 0);

            const invdate = new Date(date.toLocaleString('en-US', { timeZone: clubTimezone }));
            const diff = date.getTime() - invdate.getTime();
            const dateInClubTimezone = new Date(date.getTime() + diff);

            console.log(dateInClubTimezone)
            console.log(this.timeChoice)

            const dateEpoch : number = dateInClubTimezone.getTime();
            const timeDateEpoch : number = dateEpoch + (this.timeChoice * 60 * 1000);

            const orderInfo : OrderInfo = {
                numPeople: this.peopleChoice,
                date: dateEpoch,
                timeDate: timeDateEpoch,
                isDayAfter: this.timeChoice >= 1440
            }
            this.bookingsService.setInfo(orderInfo);

            // TODO: remove this line and class INFO variable when extras are put back
            this.info = orderInfo;

            if (this.club) {

                this.loadingService.show();

                this.tablesService.getTables(this.club?.id, orderInfo)
                    .then(tables => {
                        this.tables = tables;
                    })
                    .catch(err => {
                        this.tables = [];
                        this.toastService.error(this.translateService.instant('table_search_error_desc'), this.translateService.instant('table_search_error_title'));
                    })
                    .finally(() => {
                        this.searchDone = true;
                        this.loadingService.hide();
                    });
            }

        } else {

            this.toastService.error(this.translateService.instant('table_search_fill_fields_desc'), this.translateService.instant('table_search_fill_fields_title'));
        }
    }

    selectTable (table: Table) {

        if (table.status === "free") {

            this.bookingsService.setTable(table);
            //this.router.navigate(['/booking', this.club?.id, 'extra'], { relativeTo: this.route });

            // TODO: TEMP: we skip extras and go to the payment
            // TODO: remove order creation here when EXTRAS are put back
            const order : Order = {id: "", club: this.club, info: this.info, table: table, extras: [] };
            this.bookingsService.setOrder(order);

            this.router.navigate(['/booking', this.club?.id, 'payment'], { relativeTo: this.route });

        }
    }

    showPicsClick (table: Table, picIndex: number) {

        this.imagesObject = table.pics.map(it => ({
            image: it
        })) || [];

        this.selectedImageIndex = picIndex;
        this.showFlag = true;
    }

    closeEventHandler() {
        this.showFlag = false;
        this.selectedImageIndex = -1;
    }
}
