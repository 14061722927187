import { EventEmitter, Injectable } from '@angular/core';
import { firstValueFrom, fromEvent, Subject, map, catchError, tap, switchMap, of } from 'rxjs';
import { Club } from '../models/model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ClubsService {

  BACKEND_HOST = environment.backend;

  private clubs? : Club[];

  constructor(
    private readonly http: HttpClient
  ) { }

	public getClubs(): Promise<Club[]> {

    if (this.clubs) {
      console.log("[Clubs service]: GET ALL LOCAL");
      return new Promise<Club[]>(resolve => resolve(this.clubs || []));
    }

    console.log("[Clubs service]: GET ALL REMOTE");
    return firstValueFrom(this.http.get<Club[]>(`${this.BACKEND_HOST}/clubs`).pipe(tap(clubs => this.clubs = clubs)));
  }

  public getClub(id : string) : Promise<Club> {
    
    return new Promise<Club>(async (resolve, reject) => {

      const clubs = await this.getClubs();
      const club = clubs.find(c => c.id === id);
      club ? resolve(club) : reject("Club not found");
    });
  }
}
