import { EventEmitter, Injectable } from '@angular/core';
import { firstValueFrom, fromEvent, Subject, map, catchError, tap, switchMap, of, Observable, ReplaySubject, BehaviorSubject, filter } from 'rxjs';
import { Club, Order, OrderAddDto, OrderInfo, Table } from '../models/model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Stripe, StripeElements } from '@stripe/stripe-js';

@Injectable({
	providedIn: 'root'
})
export class BookingsService {

  BACKEND_HOST = environment.backend;

  private _club : Subject<Club | undefined> = new BehaviorSubject<Club | undefined>(undefined);
  private _info : Subject<OrderInfo | undefined> = new BehaviorSubject<OrderInfo | undefined>(undefined);
  private _table : Subject<Table | undefined> = new BehaviorSubject<Table | undefined>(undefined);
  private _order : Subject<Order | undefined> = new BehaviorSubject<Order | undefined>(undefined);

  constructor(
    private readonly http: HttpClient
  ) { }

  get club () : Observable<Club | undefined> {
    return this._club.asObservable();
  }

  get info () : Observable<OrderInfo | undefined> {
    return this._info.asObservable();
  }

  get table () : Observable<Table | undefined> {
    return this._table.asObservable();
  }

  get order () : Observable<Order | undefined> {
    return this._order.asObservable();
  }

  public setClub(club: Club): void {
    this._club.next(club);
  }

  public setInfo(orderInfo: OrderInfo): void {
    this._info.next(orderInfo);
  }

  public setTable(table: Table): void {
    this._table.next(table);
  }

  public setOrder(order: Order): void {
    this._order.next(order);
  }

  public resetData() {
    this._club.next(undefined);
    this.resetInfo();
  }

  public resetInfo() {
    this._info.next(undefined);
    this.resetTable();
  }

  public resetTable() {
    this._table.next(undefined);
    this.resetOrder();
  }

  public resetOrder() {
    this._order.next(undefined);
  }


  public askCheckout (order: Order) : Promise<{ orderId: string; clientSecret: string; stripeClientId: string; }> {

    let orderAddDto : OrderAddDto | undefined;

    if (order.info) {

        orderAddDto = {
            info: {  ...order.info },
            clubId: order.club?.id ?? "",
            tableId: order.table?.id ?? "",
            extras: (order.extras ?? []).map(e => ({ extraId: e.id, quantity: e.quantity }))
        }
    }

    return firstValueFrom(this.http.put<{ orderId: string; clientSecret: string; stripeClientId: string; }>(`${this.BACKEND_HOST}/orders`, orderAddDto));
  }

  public deletePendingOrder (orderId: string) : Promise<void> {
    return firstValueFrom(this.http.delete<void>(`${this.BACKEND_HOST}/orders/${ orderId }`).pipe(catchError(() => of(undefined))));
  }

  public editBilling (orderId: string, name: string, surname: string, email: string, phone: string) : Promise<boolean> {
    return firstValueFrom(this.http.post<{ status: boolean}>(`${this.BACKEND_HOST}/orders/${ orderId }/billing`, { billing: { name, surname, email, phone } }).pipe(map(res => res.status)));
  }

  public async payOrder (stripe: Stripe, elements: StripeElements, orderId: string, nameSurname: string, email: string, phone: string) : Promise<string> {

    let errorStr = "";

    // If no errors it should redirect => no return
    const { error } = await stripe.confirmPayment({
      elements: elements,
      confirmParams: {
        return_url: `${this.BACKEND_HOST}/orders/${ orderId }/confirm`,
        payment_method_data: {
            billing_details: {
                name: nameSurname,
                email: email,
                phone: phone
            }
        },
        receipt_email: email
      }
    });

    if (error.type === "card_error" || error.type === "validation_error") {
        errorStr = error.message || error.message || "";
        errorStr += " (" + (error.code || "") + " " + (error.decline_code || "") + ")"
    } else if (error.type === "invalid_request_error") {
        // Most probably EMAIL not valid
        errorStr = error.message || error.message || "";
    } else {
        errorStr = error.message || error.message || "";
        errorStr += " (" + (error.code || "") + " " + (error.decline_code || "") + ")"
    }

    return errorStr;
  }

  public getClosedOrder (orderId: string) : Promise<Order | undefined> {
    return firstValueFrom(this.http.get<Order>(`${this.BACKEND_HOST}/orders/${ orderId }`).pipe(catchError(() => of(undefined))));
  }
}
