import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { it, enUS, fr, es } from 'date-fns/locale';
import { DateFnsConfigurationService, DateFnsModule } from 'ngx-date-fns';
import { BookingComponent } from './components/booking/booking.component';
import { BookingTableComponent } from './components/booking/booking-table/booking-table.component';
import { BookingExtraComponent } from './components/booking/booking-extra/booking-extra.component';
import { BookingPaymentComponent } from './components/booking/booking-payment/booking-payment.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule  } from "@angular/forms";
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CurrencyPipe } from '@angular/common';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { environment } from 'src/environments/environment';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { TermsComponent } from './components/terms/terms.component';
import { TranslateCutPipe } from './utils/translate-cut.pipe';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { CustomDateAdapter } from './utils/customDateAdapter';



let preferredLanguage = 'en';

const dateLanguageConfig = new DateFnsConfigurationService();

registerLocaleData(localeEn, 'en');
dateLanguageConfig.setLocale(enUS);

if (localStorage.getItem('locale') === 'fr') {
	registerLocaleData(localeFr, 'fr');
	preferredLanguage = 'fr';
	dateLanguageConfig.setLocale(fr);
} else if (localStorage.getItem('locale') === 'en') {
	registerLocaleData(localeEn, 'en');
	preferredLanguage = 'en';
    dateLanguageConfig.setLocale(enUS);
} else {

    // Language not set yet => choose the right one from the navigator

    const userLang = navigator.language.substr(0, 2);

    if (userLang === 'fr') {
        registerLocaleData(localeFr, 'fr');
	    preferredLanguage = 'fr';
	    dateLanguageConfig.setLocale(fr);
    } else {
        registerLocaleData(localeEn, 'en');
	    preferredLanguage = 'en';
        dateLanguageConfig.setLocale(enUS);
    }
}

export const NB_FORMATS = {
	parse: {
		dateInput: 'dd/MM/yyyy'
	},
	display: {
		dateInput: 'dd/MM/yyyy'
	}
};

export const AppDateFormats = {
    parse: {
      dateInput: 'DD.MM.YYYY',
    },
    display: {
      dateInput: 'DD.MM.YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    }
  }

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/strings/', '.json?v=' + Date.now());
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {

    return () => new Promise<any>((resolve: any) => {

        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        locationInitialized.then(() => {
            translate.setDefaultLang('en');
            translate.use(preferredLanguage).subscribe(() => {
                resolve(null);
            });
        });
    });
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HomepageComponent,
    BookingComponent,
    BookingTableComponent,
    BookingExtraComponent,
    BookingPaymentComponent,
    CheckoutComponent,
    ConfirmationComponent,
    TermsComponent,
    TranslateCutPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    SnotifyModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    Ng2TelInputModule,
    MatSelectModule,
    MatButtonModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    CurrencyPipe,
    NgImageFullscreenViewModule,
    HttpClientModule,
    DateFnsModule.forRoot(),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        }
    })
  ],
  providers: [
    SnotifyService,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    {
        provide: APP_INITIALIZER,
        useFactory: appInitializerFactory,
        deps: [TranslateService, Injector],
        multi: true
    },
    { provide: DateFnsConfigurationService, useValue: dateLanguageConfig },

    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
