import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, startWith, takeUntil } from 'rxjs';
import { Club } from 'src/app/models/model';
import { BookingsService } from 'src/app/services/bookings.service';
import { ClubsService } from 'src/app/services/clubs.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, OnDestroy {

  mobile : boolean = false;

  private ngUnsubscribe = new Subject<void>();

  clubs : Club[] = [];
  isLoading : boolean = true;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly clubsService: ClubsService,
    private readonly loadingService: LoadingService,
    private readonly deviceStateService: DeviceStateService,
    private readonly bookingsService: BookingsService
  ) {
    this.mobile = this.deviceStateService.isMobileResolution();
  }

  ngOnInit(): void {
    this.bookingsService.resetData();
    
    this.loadingService.show();

    this.clubsService.getClubs()
      .then(clubs => {
        this.clubs = clubs;
        this.loadingService.hide();
      })
      .catch(err => {
        console.log(err);
        this.loadingService.hide();

        // TODO: manage this use case => error page
        window.location.href = "https://nightbite.vip";
      });

    this.deviceStateService.screenResized
      .pipe(
          startWith(this.mobile),
          takeUntil(this.ngUnsubscribe)
      )
      .subscribe(isMobile => this.mobile = isMobile);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  tileClick(clubId: string) {
    this.router.navigate(['booking', clubId], { relativeTo: this.route });
  }
}
