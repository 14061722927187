import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest, of, startWith, switchMap, take, takeUntil } from 'rxjs';
import { Club, Extra, ExtraOrder, Order, OrderInfo, Table } from 'src/app/models/model';
import { BookingsService } from 'src/app/services/bookings.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { ExtrasService } from 'src/app/services/extras.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-booking-extra',
  templateUrl: './booking-extra.component.html',
  styleUrls: ['./booking-extra.component.scss']
})
export class BookingExtraComponent implements OnInit, OnDestroy {

  @ViewChild("checkoutmobile") checkoutMobile: ElementRef | undefined;

  club?: Club;
  info?: OrderInfo;
  table?: Table;
  extras: ExtraOrder[] = [];
  
  extraDiscount = 15 // % TEMP! TODO: manage discounts

  extra_buy_now: string = "";

  selectedExtras : ExtraOrder[] = [];

  confirmOrderFromOutside: EventEmitter<void> = new EventEmitter<void>();

  mobile: boolean = false;
  private ngUnsubscribe = new Subject<void>();
  
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly loadingService: LoadingService,
    private readonly deviceStateService: DeviceStateService,
    private readonly location: Location,
    private readonly translateService: TranslateService,
    private readonly bookingsService: BookingsService,
    private readonly extrasService: ExtrasService
  ) {
    this.mobile = this.deviceStateService.isMobileResolution();
  }

  ngOnInit(): void {

    this.bookingsService.resetOrder();
    this.loadingService.show();

    combineLatest([
        this.bookingsService.club,
        this.bookingsService.info,
        this.bookingsService.table
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        switchMap(([club, info, table]) => {
          if (club && info && table) {
            this.club = club;
            this.info = info;
            this.table = table;
            
            return this.extrasService.getExtras(club?.id);
          }
          
          this.backClick();
          return of([]);
        })
      )
      .subscribe(extras => {
        this.loadingService.hide();
        this.extras = extras.map(e => ({ ...e, quantity: 1 }));
      });

      this.extra_buy_now = this.translateService.instant('extra_buy_now').replace('$$$', ''+this.extraDiscount);


    this.deviceStateService.screenResized
        .pipe(
            startWith(this.mobile),
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(isMobile => this.mobile = isMobile);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  backClick () {
    this.location.back();
  }

  toggleExtra (extra : ExtraOrder) {
    if (!this.selectedExtras.find(e => e.id === extra.id)) {
        this.selectedExtras = [...this.selectedExtras, {...extra, quantity: 1}];
        extra.quantity = 1;
    } else {
        this.selectedExtras = this.selectedExtras.filter(e => e.id !== extra.id);
        extra.quantity = 1;
    }
  }

  isExtraSelected (extra: Extra) : boolean {
    return !!this.selectedExtras.find(e => e.id === extra.id);
  }

  extraRemoved (extra: ExtraOrder) {
    this.toggleExtra(extra);
  }

  viewOrder () {
    if (this.checkoutMobile) {
      this.checkoutMobile.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  decreaseExtra (extra: ExtraOrder) {
    if (extra.quantity > 1) {
      extra.quantity -= 1;

      const e = this.selectedExtras.find(e => e.id === extra.id);
      if (e) {
        e.quantity = extra.quantity;
        
        this.selectedExtras = this.selectedExtras.concat([]);
      }
    } else {
      this.extraRemoved(extra);
    }
  }

  increaseExtra (extra: ExtraOrder) {
    if (extra.quantity < 10) { // TODO: MAX SUPPLY ? => AVAILABILITY MANAGEMENT
      extra.quantity += 1;

      const e = this.selectedExtras.find(e => e.id === extra.id);
      if (e) {
        e.quantity = extra.quantity;
        
        this.selectedExtras = this.selectedExtras.concat([]);
      }
    }
  }

  quantityChanged (extra: ExtraOrder) {
    const e = this.extras.find(e => e.id === extra.id);
    if (e) {
      e.quantity = extra.quantity;
    }

    const e1 = this.selectedExtras.find(e => e.id === extra.id);
    if (e1) {
      e1.quantity = extra.quantity;
    }
  }

  orderCompleted (order: Order) {
    this.bookingsService.setOrder(order);
    this.router.navigate(['/booking', this.club?.id, 'payment'], { relativeTo: this.route });
  }
}
